<template>
  <div class="stores">
    <div class="stores-title">
      <span>用户管理</span>
      <el-button type="primary" size="small" @click="addPing">新增账号</el-button>
    </div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="品牌">
        <el-select v-model="formInline.ping" placeholder="全部" @change="pingClik">
          <el-option :label="item.name" :value="item.id" v-for="(item,index) in pinglist" :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门店">
        <el-select v-model="formInline.men" placeholder="全部" @change="menClick">
          <el-option :label="item.name" :value="item.id" v-for="(item,index) in menData" :key="index"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="cont">
      <el-table
          :header-cell-style="headCalss"
          :max-height="heightmAC"
          :data="tableData"
          border
          style="width: 100%">
        <el-table-column
            prop="brand_name"
            align="center"
            label="品牌">
        </el-table-column>
        <el-table-column
            prop="real_name"
            align="center"
            label="姓名">
        </el-table-column>
        <el-table-column
            prop="account"
            align="center"
            label="账号">
        </el-table-column>
        <el-table-column
            prop="auth_store_names"
            align="center"
            label="门店">
        </el-table-column>
        <el-table-column
            prop="id"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <div class="caoStyle">
              <span @click="dellMen(scope.row)">删除</span>
              <span @click="editMen(scope.row)">修改</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 账号弹出层 -->
    <el-dialog
        :title="pingTitle"
        :visible.sync="pingShow"
        width="30%">
      <!-- 账号 -->
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="KTV品牌" prop="ping">
            <el-select v-model="ruleForm.ping" :disabled="pingTitle == '修改账号'"  placeholder="请选择品牌" style="width:100%" @change="xuanClick">
              <el-option :label="item.name" :value="item.id" v-for="(item,index) in pinglist" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="权限门店" prop="men">
            <el-select v-model="ruleForm.men" placeholder="请选择门店" style="width:100%" multiple collapse-tags>
              <el-option :label="item.name" :value="item.id" v-for="(item,index) in xuanData" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="真实姓名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="账号" prop="zhang" v-if="pingTitle != '修改账号'">
            <el-input v-model="ruleForm.zhang"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" v-if="pingTitle != '修改账号'">
            <el-input v-model="ruleForm.password" show-password></el-input>
          </el-form-item>
          <el-form-item label="密码" v-else>
            <el-input v-model="ruleForm.password" show-password></el-input>
          </el-form-item>
        </el-form>
        <div class="gong">
          <div>功能权限</div>
          <el-tree
              node-key="id"
              @check-change="handleCheckChange"
              :props="props"
              ref="tree"
              :data="manList"
              :default-checked-keys="checkedId"
              show-checkbox>
          </el-tree>
        </div>
        <div class="pingAnniu">
          <el-button type="primary" size="medium" @click="baoPing">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {brandList, userList, menuList, addUser, editUser, storeList, delUser} from "../../api/index";

export default {
  data() {
    return {
      formInline: {
        ping: '',
        men: ''
      },
      pinglist: [],
      menData: [],
      manList: [],
      pingTitle: '新增账号',
      ruleForm: {
        ping: '',
        men: [],
        name: '',
        zhang: '',
        password: ''
      },
      rules: {
        ping: [
          {required: true, message: '请输入品牌名称', trigger: 'change'}
        ],
        men: [
          {required: true, message: '请输入品牌名称', trigger: 'change'}
        ],
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        zhang: [
          {required: true, message: '请输入账号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      },
      props: {
        label: 'name',
        children: 'children'
      },
      manId: '',
      checkedId: [],
      liItem: null,
      xuanData: [],


      //品牌
      pingIndex: 0,
      pingShow: false,
      pingRow: null,
      // 门店
      tableData: [],
      heightmAC: window.innerHeight - 230,
      zhangShow: false,
      menForm: {
        name: '',
        premises_id: '',
        cs_store_id: ''
      },
      menRules: {
        name: [
          {required: true, message: '请输入门店名称', trigger: 'blur'}
        ],
        premises_id: [
          {required: true, message: '请输入门店狗号', trigger: 'blur'}
        ]
      },
      menRow: null
    }
  },
  created() {
    this._brandList()
    this._menuList()
  },
  methods: {
    //获取菜单列表
    _menuList() {
      menuList().then(Er => {
        if (Er.return_code == 200) {
          this.manList = Er.data
        }
      })
    },
    pingClik(val) {
      this._storeList(val)
      this._userList()
    },
    menClick(val) {
      console.log(1)
      this.formInline.men = val
      this._userList()
    },
    xuanClick(val) {
      this.xuanData = []
      this.ruleForm.men = []
      storeList({
        brand_id: val
      }).then(Er => {
        if (Er.return_code == 200) {
          if (Er.data && Er.data.length > 0) {
            this.xuanData = Er.data
          } else {
            this.xuanData = []
          }
        }
      })
    },
    // 品牌模块
    _brandList() {
      brandList().then(Er => {
        if (Er.data && Er.data.length > 0) {
          this.pinglist = Er.data
          this._storeList(Er.data[0].id)
          this.formInline.ping = Er.data[0].id
        }
      })
    },
    _storeList(row) {
      storeList({
        brand_id: row
      }).then(Er => {
        if (Er.return_code == 200) {
          if (Er.data && Er.data.length > 0) {
            this.menData = Er.data
            this.formInline.men = Er.data[0].id
            this._userList()
          } else {
            this.menData = []
          }
        }
      })
    },
    _userList() {
      userList({
        brand_id: this.formInline.ping,
        store_id: this.formInline.men,
      }).then(Er => {
        console.log(Er)
        if (Er.return_code == 200) {
          if (Er.data.data && Er.data.data.length > 0) {
            this.tableData = Er.data.data
          } else {
            this.tableData = []
          }
        }

      })
    },
    addPing() {
      //新增账号
      this.pingShow = true
      //清空输入框
      if (this.$refs.ruleForm) {
        this.$refs.tree.setCheckedKeys([]);
        this.$refs.ruleForm.resetFields()
        this.ruleForm = {
          ping: '',
          men: [],
          name: '',
          zhang: '',
          password: ''
        }
      }
      this.pingTitle = '新增账号'
    },
    baoPing() {
      let _this = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let res = this.$refs.tree.getCheckedNodes()
          let arrDeptId = ''
          res.forEach((item, index) => {
            if (res.length - 1 == index) {
              arrDeptId += item.id
            } else {
              arrDeptId += item.id + ','
            }
          })
          console.log(res)
          this.manId = arrDeptId
          //处理门店数据
          let text = ''
          _this.ruleForm.men.map((ec, index) => {
            if (_this.ruleForm.men.length - 1 == index) {
              text += ec
            } else {
              text += ec + ','
            }
          })
          if (_this.liItem) {
            editUser({
              user_id: _this.liItem.user_id,
              brand_id: _this.ruleForm.ping,
              store_ids: text,
              menu_ids: _this.manId,
              real_name: _this.ruleForm.name,
              password: _this.ruleForm.password,
            }).then(Er => {
              if (Er.return_code == 200) {
                _this.pingShow = false
                _this._userList()
                _this.$message({
                  message: "修改成功",
                  type: "success",
                });
                _this.liItem = null
              }
            })
          } else {
            addUser({
              brand_id: _this.ruleForm.ping,
              store_ids: text,
              menu_ids: _this.manId,
              real_name: _this.ruleForm.name,
              account: _this.ruleForm.zhang,
              password: _this.ruleForm.password,
            }).then(Er => {
              if (Er.return_code == 200) {
                _this.pingShow = false
                _this._userList()
                _this.$message({
                  message: "添加成功",
                  type: "success",
                });
              }
            })
          }

        } else {
          return false;
        }
      });
    },
    // 获取选中的权限
    handleCheckChange() {
      let res = this.$refs.tree.getCheckedNodes()
      let arrDeptId = ''
      res.forEach((item, index) => {
        if (res.length - 1 == index) {
          arrDeptId += item.id
        } else {
          arrDeptId += item.id + ','
        }
      })
      this.manId = arrDeptId
    },
    editMen(row) {
      this.liItem = row
      storeList({
        brand_id: row.brand_id
      }).then(Er => {
        if (Er.return_code == 200) {
          if (Er.data && Er.data.length > 0) {
            this.xuanData = Er.data
          } else {
            this.xuanData = []
          }
          //清空输入框
          if (this.$refs.ruleForm) {
            this.$refs.ruleForm.resetFields()
          }
          this.pingTitle = '修改账号'
          this.ruleForm = {
            ping: row.brand_id,
            men: row.auth_store_ids.split(',').map(Number),
            name: row.real_name,
            zhang: row.account,
          }
          let attr = row.auth_menu_ids.split(',')
          this.checkedId = attr
          this.menForm = {
            name: row.name,
            premises_id: row.premises_id,
            cs_store_id: row.cs_store_id
          }
          this.pingShow = true
        }
      })
    },
    dellMen(row) {
      let _this = this
      this.$confirm('确认删除？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUser({
          user_id: row.user_id,
          brand_id: _this.formInline.ping
        }).then(Er => {
          if (Er.return_code == 200) {
            _this._userList()
            _this.zhangShow = false
            _this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        })

      })
    },
    //门店模块
    headCalss() {
      return 'background:#f5f7fa'
    },
  }
}
</script>

<style lang="scss" scoped>
.stores {
  .stores-title {
    display: flex;
    align-items: center;
    height: 80px;

    > span {
      font-size: 20px;
      margin-top: -2px;
      margin-right: 10px;
    }
  }

  .cont {
    display: flex;
    height: calc(100% - 80px);
    width: 100%;
  }
}

.pingAnniu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.caoStyle {
  display: flex;
  justify-content: center;

  > span {
    color: #409EFF;

    &:nth-child(1) {
      margin-right: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.gong {
  > div {
    margin-bottom: 5px;
  }
}
</style>